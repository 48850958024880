var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c(
                  "b-tabs",
                  { attrs: { "content-class": "p-3 text-muted" } },
                  [
                    _c(
                      "b-tab",
                      {
                        staticClass: "border-0",
                        attrs: { active: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "d-inline-block d-sm-none" },
                                  [
                                    _c("i", {
                                      staticClass: "mdi mdi-numeric-1-circle",
                                    }),
                                  ]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-inline-block" },
                                  [_vm._v("Schritt 1")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        _c("div", { staticClass: "row mt-3" }, [
                          _c("div", { staticClass: "col-xl-12" }, [
                            _c(
                              "div",
                              { attrs: { role: "tablist" } },
                              [
                                _c(
                                  "b-card",
                                  {
                                    staticClass: "mb-1",
                                    attrs: { "no-body": "" },
                                  },
                                  [
                                    _c(
                                      "b-card-header",
                                      {
                                        attrs: {
                                          "header-tag": "header",
                                          role: "tab",
                                        },
                                      },
                                      [
                                        _c("h6", { staticClass: "m-0" }, [
                                          _c(
                                            "a",
                                            {
                                              directives: [
                                                {
                                                  name: "b-toggle",
                                                  rawName:
                                                    "v-b-toggle.accordion-11",
                                                  modifiers: {
                                                    "accordion-11": true,
                                                  },
                                                },
                                              ],
                                              staticClass: "text-dark",
                                              attrs: {
                                                href: "javascript: void(0);",
                                              },
                                            },
                                            [_vm._v("Name")]
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "b-collapse",
                                      {
                                        attrs: {
                                          id: "accordion-11",
                                          visible: "",
                                          accordion: "my-accordion",
                                          role: "tabpanel",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card-body",
                                          [
                                            _c("b-card-text", [
                                              _c("ul", [
                                                _c("li", [
                                                  _vm._v(
                                                    " Geben Sie einen beliebigen Namen für Ihr Datenfile ein. "
                                                  ),
                                                ]),
                                                _c("li", [
                                                  _vm._v(
                                                    "Maximal 100. Zeichen"
                                                  ),
                                                ]),
                                              ]),
                                              _c(
                                                "div",
                                                { staticClass: "row mt-4" },
                                                [
                                                  _c("div", {
                                                    staticClass: "col-md-6",
                                                  }),
                                                ]
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "col-xl-12" }, [
                            _c(
                              "div",
                              { attrs: { role: "tablist" } },
                              [
                                _c(
                                  "b-card",
                                  {
                                    staticClass: "mb-1",
                                    attrs: { "no-body": "" },
                                  },
                                  [
                                    _c(
                                      "b-card-header",
                                      {
                                        attrs: {
                                          "header-tag": "header",
                                          role: "tab",
                                        },
                                      },
                                      [
                                        _c("h6", { staticClass: "m-0" }, [
                                          _c(
                                            "a",
                                            {
                                              directives: [
                                                {
                                                  name: "b-toggle",
                                                  rawName:
                                                    "v-b-toggle.accordion-12",
                                                  modifiers: {
                                                    "accordion-12": true,
                                                  },
                                                },
                                              ],
                                              staticClass: "text-dark",
                                              attrs: {
                                                href: "javascript: void(0);",
                                              },
                                            },
                                            [_vm._v("Beschreibung")]
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "b-collapse",
                                      {
                                        attrs: {
                                          id: "accordion-12",
                                          visible: "",
                                          accordion: "my-accordion",
                                          role: "tabpanel",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card-body",
                                          [
                                            _c("b-card-text", [
                                              _c("ul", [
                                                _c("li", [
                                                  _vm._v(
                                                    " Sie können optional eine zusätzliche Beschreibung hinzufügen. "
                                                  ),
                                                ]),
                                                _c("li", [
                                                  _vm._v("Maximal 500 Zeichen"),
                                                ]),
                                              ]),
                                              _c(
                                                "div",
                                                { staticClass: "row mt-4" },
                                                [
                                                  _c("div", {
                                                    staticClass: "col-md-6",
                                                  }),
                                                ]
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "col-xl-12" }, [
                            _c(
                              "div",
                              { attrs: { role: "tablist" } },
                              [
                                _c(
                                  "b-card",
                                  {
                                    staticClass: "mb-1",
                                    attrs: { "no-body": "" },
                                  },
                                  [
                                    _c(
                                      "b-card-header",
                                      {
                                        attrs: {
                                          "header-tag": "header",
                                          role: "tab",
                                        },
                                      },
                                      [
                                        _c("h6", { staticClass: "m-0" }, [
                                          _c(
                                            "a",
                                            {
                                              directives: [
                                                {
                                                  name: "b-toggle",
                                                  rawName:
                                                    "v-b-toggle.accordion-13",
                                                  modifiers: {
                                                    "accordion-13": true,
                                                  },
                                                },
                                              ],
                                              staticClass: "text-dark",
                                              attrs: {
                                                href: "javascript: void(0);",
                                              },
                                            },
                                            [_vm._v("Erhebungsmethode")]
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "b-collapse",
                                      {
                                        attrs: {
                                          id: "accordion-13",
                                          visible: "",
                                          accordion: "my-accordion",
                                          role: "tabpanel",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card-body",
                                          [
                                            _c("b-card-text", [
                                              _c("ul", [
                                                _c("li", [
                                                  _vm._v(
                                                    " Geben Sie die Erhebungsmethode/n an, mit der Sie die Daten erhoben haben. "
                                                  ),
                                                ]),
                                                _c("li", [
                                                  _vm._v(
                                                    " Wählen Sie zwischen folgenden Methoden "
                                                  ),
                                                  _c("ul", [
                                                    _c("li", [
                                                      _vm._v(
                                                        " CAWI - Computer Assisted Web Interviews "
                                                      ),
                                                    ]),
                                                    _c("li", [
                                                      _vm._v(
                                                        " CATI - Computer Assisted Telephone Interviews "
                                                      ),
                                                    ]),
                                                    _c("li", [
                                                      _vm._v(
                                                        " CAPI - Computer Assisted Personal Interviews "
                                                      ),
                                                    ]),
                                                    _c("li", [
                                                      _vm._v(
                                                        " PAPI - Paper Assisted Personal Interviews "
                                                      ),
                                                    ]),
                                                  ]),
                                                ]),
                                                _c("li", [
                                                  _vm._v(
                                                    " Bei Mixed-Mode Studien können Sie mehrere Items auswählen. "
                                                  ),
                                                ]),
                                              ]),
                                              _c(
                                                "div",
                                                { staticClass: "row mt-4" },
                                                [
                                                  _c("div", {
                                                    staticClass: "col-md-6",
                                                  }),
                                                ]
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "col-xl-12" }, [
                            _c(
                              "div",
                              { attrs: { role: "tablist" } },
                              [
                                _c(
                                  "b-card",
                                  {
                                    staticClass: "mb-1",
                                    attrs: { "no-body": "" },
                                  },
                                  [
                                    _c(
                                      "b-card-header",
                                      {
                                        attrs: {
                                          "header-tag": "header",
                                          role: "tab",
                                        },
                                      },
                                      [
                                        _c("h6", { staticClass: "m-0" }, [
                                          _c(
                                            "a",
                                            {
                                              directives: [
                                                {
                                                  name: "b-toggle",
                                                  rawName:
                                                    "v-b-toggle.accordion-14",
                                                  modifiers: {
                                                    "accordion-14": true,
                                                  },
                                                },
                                              ],
                                              staticClass: "text-dark",
                                              attrs: {
                                                href: "javascript: void(0);",
                                              },
                                            },
                                            [_vm._v("Dateiformat und Struktur")]
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "b-collapse",
                                      {
                                        attrs: {
                                          id: "accordion-14",
                                          visible: "",
                                          accordion: "my-accordion",
                                          role: "tabpanel",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card-body",
                                          [
                                            _c("b-card-text", [
                                              _c("ul", [
                                                _c("li", [
                                                  _vm._v(
                                                    ".xlsx Format (Microsoft Excel)"
                                                  ),
                                                ]),
                                                _c("li", [
                                                  _vm._v(
                                                    " Stellen Sie sicher, dass sich alle Daten auf einem Tabellenblatt befinden. Sollte das Datenfile mehrere Tabellenblätter enthalten kann nur das erste Tabellenblatt verwendet werden. "
                                                  ),
                                                ]),
                                                _c("li", [
                                                  _vm._v(
                                                    " Zeilen: Datensätze müssen auf Zeilenebene spezifiziert sein (1 Datensatz - 1 Zeile) "
                                                  ),
                                                ]),
                                                _c("li", [
                                                  _vm._v(
                                                    " Spalten: Fragen, Filter, und andere Befragungsdaten müssen auf Spaltenebene spezifiziert sein. "
                                                  ),
                                                ]),
                                                _c("li", [
                                                  _vm._v(
                                                    " Projektionsspalten dürfen nur numerische Werte ohne Komma enthalten (z.B. 25, 96, 40,...) "
                                                  ),
                                                ]),
                                                _c("li", [
                                                  _vm._v(
                                                    " Nach Ende des letzten Datensatzes muss die Tabelle leer sein und darf keine weiteren Werte enthalten. "
                                                  ),
                                                ]),
                                                _c("li", [
                                                  _vm._v(
                                                    " Abhängig von Ihrem Browser, dürfen Datenfiles nur eine begrenzte Anzahl an Zeichen enthalten. "
                                                  ),
                                                  _c("ul", [
                                                    _c("li", [
                                                      _vm._v(
                                                        "Chrome: 5242878 Zeichen"
                                                      ),
                                                    ]),
                                                    _c("li", [
                                                      _vm._v(
                                                        "Firefox: 5242883 Zeichen"
                                                      ),
                                                    ]),
                                                    _c("li", [
                                                      _vm._v(
                                                        "Edge: 122070 Zeichen"
                                                      ),
                                                    ]),
                                                  ]),
                                                ]),
                                              ]),
                                              _c(
                                                "div",
                                                { staticClass: "row mt-4" },
                                                [
                                                  _c("div", {
                                                    staticClass: "col-md-6",
                                                  }),
                                                ]
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "col-xl-12" }, [
                            _c(
                              "div",
                              { attrs: { role: "tablist" } },
                              [
                                _c(
                                  "b-card",
                                  {
                                    staticClass: "mb-1",
                                    attrs: { "no-body": "" },
                                  },
                                  [
                                    _c(
                                      "b-card-header",
                                      {
                                        attrs: {
                                          "header-tag": "header",
                                          role: "tab",
                                        },
                                      },
                                      [
                                        _c("h6", { staticClass: "m-0" }, [
                                          _c(
                                            "a",
                                            {
                                              directives: [
                                                {
                                                  name: "b-toggle",
                                                  rawName:
                                                    "v-b-toggle.accordion-15",
                                                  modifiers: {
                                                    "accordion-15": true,
                                                  },
                                                },
                                              ],
                                              staticClass: "text-dark",
                                              attrs: {
                                                href: "javascript: void(0);",
                                              },
                                            },
                                            [_vm._v("Spaltenüberschriften")]
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "b-collapse",
                                      {
                                        attrs: {
                                          id: "accordion-15",
                                          visible: "",
                                          accordion: "my-accordion",
                                          role: "tabpanel",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card-body",
                                          [
                                            _c("b-card-text", [
                                              _c("ul", [
                                                _c("li", [
                                                  _vm._v(
                                                    " Geben Sie an, in welcher Zeile sich die Spaltenüberschriften beziehungsweise die Spaltenbezeichnungen befinden. "
                                                  ),
                                                ]),
                                              ]),
                                              _c(
                                                "div",
                                                { staticClass: "row mt-4" },
                                                [
                                                  _c("div", {
                                                    staticClass: "col-md-6",
                                                  }),
                                                ]
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "col-xl-12" }, [
                            _c(
                              "div",
                              { attrs: { role: "tablist" } },
                              [
                                _c(
                                  "b-card",
                                  {
                                    staticClass: "mb-1",
                                    attrs: { "no-body": "" },
                                  },
                                  [
                                    _c(
                                      "b-card-header",
                                      {
                                        attrs: {
                                          "header-tag": "header",
                                          role: "tab",
                                        },
                                      },
                                      [
                                        _c("h6", { staticClass: "m-0" }, [
                                          _c(
                                            "a",
                                            {
                                              directives: [
                                                {
                                                  name: "b-toggle",
                                                  rawName:
                                                    "v-b-toggle.accordion-16",
                                                  modifiers: {
                                                    "accordion-16": true,
                                                  },
                                                },
                                              ],
                                              staticClass: "text-dark",
                                              attrs: {
                                                href: "javascript: void(0);",
                                              },
                                            },
                                            [_vm._v("Erster Datensatz")]
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "b-collapse",
                                      {
                                        attrs: {
                                          id: "accordion-16",
                                          visible: "",
                                          accordion: "my-accordion",
                                          role: "tabpanel",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card-body",
                                          [
                                            _c("b-card-text", [
                                              _c("ul", [
                                                _c("li", [
                                                  _vm._v(
                                                    " Definieren Sie, in welcher Zeile sich der erste Ihrer Datensätze / Befragten befindet. "
                                                  ),
                                                ]),
                                              ]),
                                              _c(
                                                "div",
                                                { staticClass: "row mt-4" },
                                                [
                                                  _c("div", {
                                                    staticClass: "col-md-6",
                                                  }),
                                                ]
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]
                    ),
                    _c(
                      "b-tab",
                      {
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "d-inline-block d-sm-none" },
                                  [
                                    _c("i", {
                                      staticClass: "mdi mdi-numeric-2-circle",
                                    }),
                                  ]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-inline-block" },
                                  [_vm._v("Schritt 2")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        _c("div", { staticClass: "row mt-3" }, [
                          _c("div", { staticClass: "col-md-12" }, [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-md-12" }, [
                                _c("ul", [
                                  _c("li", [
                                    _vm._v(
                                      " Im zweiten Schritt bestimmen Sie nun die Daten in Ihrem Datenfile, welche für die Analyse durch Redem relevant sind. "
                                    ),
                                  ]),
                                  _c("li", [
                                    _vm._v(
                                      " Um eine neue Spalte hinzufügen zu können, müssen Sie die grüne Schaltfläche “Neue Spalte hinzufügen” klicken. "
                                    ),
                                  ]),
                                  _c("li", [
                                    _vm._v(
                                      " Beachten Sie, dass bevor Sie eine neue Spalte hinzufügen können, die vorherige Spalte bereits fertig spezifiziert sein muss. Das heißt, es können nicht mehrere Spalten gleichzeitig hinzugefügt werden. "
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]),
                            _c("div", { staticClass: "row mt-4" }, [
                              _c("div", { staticClass: "col-xl-12" }, [
                                _c(
                                  "div",
                                  { attrs: { role: "tablist" } },
                                  [
                                    _c(
                                      "b-card",
                                      {
                                        staticClass: "mb-1",
                                        attrs: { "no-body": "" },
                                      },
                                      [
                                        _c(
                                          "b-card-header",
                                          {
                                            attrs: {
                                              "header-tag": "header",
                                              role: "tab",
                                            },
                                          },
                                          [
                                            _c("h6", { staticClass: "m-0" }, [
                                              _c(
                                                "a",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-toggle",
                                                      rawName:
                                                        "v-b-toggle.accordion-1",
                                                      modifiers: {
                                                        "accordion-1": true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass: "text-dark",
                                                  attrs: {
                                                    href: "javascript: void(0);",
                                                  },
                                                },
                                                [_vm._v("Spalte auswählen")]
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "b-collapse",
                                          {
                                            attrs: {
                                              id: "accordion-1",
                                              visible: "",
                                              accordion: "my-accordion",
                                              role: "tabpanel",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card-body",
                                              [
                                                _c("b-card-text", [
                                                  _c("ul", [
                                                    _c("li", [
                                                      _vm._v(
                                                        " Wählen Sie aus, welche Spalte Sie spezifizieren möchten. "
                                                      ),
                                                    ]),
                                                    _c("li", [
                                                      _vm._v(
                                                        " Es werden alle Spalten mit den Bezeichnungen aus dem Datenfile in einem Dropdown-Menü angezeigt. "
                                                      ),
                                                    ]),
                                                    _c("li", [
                                                      _vm._v(
                                                        " Sie können auch nach dem Namen der gewünschten Spalte suchen. "
                                                      ),
                                                    ]),
                                                  ]),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "col-xl-12" }, [
                                _c(
                                  "div",
                                  { attrs: { role: "tablist" } },
                                  [
                                    _c(
                                      "b-card",
                                      {
                                        staticClass: "mb-1",
                                        attrs: { "no-body": "" },
                                      },
                                      [
                                        _c(
                                          "b-card-header",
                                          {
                                            attrs: {
                                              "header-tag": "header",
                                              role: "tab",
                                            },
                                          },
                                          [
                                            _c("h6", { staticClass: "m-0" }, [
                                              _c(
                                                "a",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-toggle",
                                                      rawName:
                                                        "v-b-toggle.accordion-2",
                                                      modifiers: {
                                                        "accordion-2": true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass: "text-dark",
                                                  attrs: {
                                                    href: "javascript: void(0);",
                                                  },
                                                },
                                                [_vm._v("Daten bestimmen")]
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "b-collapse",
                                          {
                                            attrs: {
                                              id: "accordion-2",
                                              accordion: "my-accordion",
                                              role: "tabpanel",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card-body",
                                              [
                                                _c("b-card-text", [
                                                  _c("ul", [
                                                    _c("li", [
                                                      _vm._v(
                                                        " Bestimmen Sie, um welche Art von Daten es sich handelt. "
                                                      ),
                                                    ]),
                                                    _c("li", [
                                                      _vm._v(
                                                        " Sie können zwischen folgenden Datenkategorien unterscheiden: "
                                                      ),
                                                      _c("ul", [
                                                        _c("li", [
                                                          _vm._v(
                                                            "Datensatz-ID"
                                                          ),
                                                        ]),
                                                        _c("li", [
                                                          _vm._v("Frage"),
                                                        ]),
                                                        _c("li", [
                                                          _vm._v(
                                                            "Filtervariable"
                                                          ),
                                                        ]),
                                                        _c("li", [
                                                          _vm._v(
                                                            "Zusätzlicher Gewichtungsfaktor"
                                                          ),
                                                        ]),
                                                      ]),
                                                    ]),
                                                  ]),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "col-xl-12" }, [
                                _c(
                                  "div",
                                  { attrs: { role: "tablist" } },
                                  [
                                    _c(
                                      "b-card",
                                      {
                                        staticClass: "mb-1",
                                        attrs: { "no-body": "" },
                                      },
                                      [
                                        _c(
                                          "b-card-header",
                                          {
                                            attrs: {
                                              "header-tag": "header",
                                              role: "tab",
                                            },
                                          },
                                          [
                                            _c("h6", { staticClass: "m-0" }, [
                                              _c(
                                                "a",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-toggle",
                                                      rawName:
                                                        "v-b-toggle.accordion-7",
                                                      modifiers: {
                                                        "accordion-7": true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass: "text-dark",
                                                  attrs: {
                                                    href: "javascript: void(0);",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "Angaben bearbeiten und löschen"
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "b-collapse",
                                          {
                                            attrs: {
                                              id: "accordion-7",
                                              accordion: "my-accordion",
                                              role: "tabpanel",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card-body",
                                              [
                                                _c("b-card-text", [
                                                  _c("ul", [
                                                    _c("li", [
                                                      _vm._v(
                                                        " Sie können durch Klicken des “Bearbeiten-Icons”, Ihre Angaben jederzeit bearbeiten. Wenn Sie im Bearbeitungsmodus auf das “Löschen-Icon” klicken, werden die Angaben in dieser Spalte entfernt. "
                                                      ),
                                                    ]),
                                                  ]),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "row mt-5" }, [
                              _c("div", { staticClass: "col-md-12 mb-5" }, [
                                _c("h6", { staticClass: "mb-3" }, [
                                  _vm._v("Datensatz-ID hochladen"),
                                ]),
                                _c(
                                  "div",
                                  { attrs: { role: "tablist" } },
                                  [
                                    _c(
                                      "b-card",
                                      {
                                        staticClass: "mb-1",
                                        attrs: { "no-body": "" },
                                      },
                                      [
                                        _c(
                                          "b-card-header",
                                          {
                                            attrs: {
                                              "header-tag": "header",
                                              role: "tab",
                                            },
                                          },
                                          [
                                            _c("h6", { staticClass: "m-0" }, [
                                              _c(
                                                "a",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-toggle",
                                                      rawName:
                                                        "v-b-toggle.accordion-41",
                                                      modifiers: {
                                                        "accordion-41": true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass: "text-dark",
                                                  attrs: {
                                                    href: "javascript: void(0);",
                                                  },
                                                },
                                                [_vm._v("Spalte auswählen")]
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "b-collapse",
                                          {
                                            attrs: {
                                              id: "accordion-41",
                                              visible: "",
                                              accordion: "my-accordion",
                                              role: "tabpanel",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card-body",
                                              [
                                                _c("b-card-text", [
                                                  _c("ul", [
                                                    _c("li", [
                                                      _vm._v(
                                                        " Wählen Sie aus, welche Spalte Sie spezifizieren möchten. "
                                                      ),
                                                    ]),
                                                  ]),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-card",
                                      {
                                        staticClass: "mb-1",
                                        attrs: { "no-body": "" },
                                      },
                                      [
                                        _c(
                                          "b-card-header",
                                          {
                                            attrs: {
                                              "header-tag": "header",
                                              role: "tab",
                                            },
                                          },
                                          [
                                            _c("h6", { staticClass: "m-0" }, [
                                              _c(
                                                "a",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-toggle",
                                                      rawName:
                                                        "v-b-toggle.accordion-42",
                                                      modifiers: {
                                                        "accordion-42": true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass: "text-dark",
                                                  attrs: {
                                                    href: "javascript: void(0);",
                                                  },
                                                },
                                                [_vm._v("Daten bestimmen")]
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "b-collapse",
                                          {
                                            attrs: {
                                              id: "accordion-42",
                                              visible: "",
                                              accordion: "my-accordion",
                                              role: "tabpanel",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card-body",
                                              [
                                                _c("b-card-text", [
                                                  _c("ul", [
                                                    _c("li", [
                                                      _vm._v(
                                                        " Bestimmen Sie die Spalte mit “Datensatz-ID”. "
                                                      ),
                                                    ]),
                                                  ]),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-card",
                                      {
                                        staticClass: "mb-1",
                                        attrs: { "no-body": "" },
                                      },
                                      [
                                        _c(
                                          "b-card-header",
                                          {
                                            attrs: {
                                              "header-tag": "header",
                                              role: "tab",
                                            },
                                          },
                                          [
                                            _c("h6", { staticClass: "m-0" }, [
                                              _c(
                                                "a",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-toggle",
                                                      rawName:
                                                        "v-b-toggle.accordion-73",
                                                      modifiers: {
                                                        "accordion-73": true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass: "text-dark",
                                                  staticStyle: {
                                                    cursor: "default",
                                                  },
                                                  attrs: {
                                                    href: "javascript: void(0);",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "Speichern Sie den Vorgang"
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "col-md-12 mb-5" }, [
                                _c("h6", [_vm._v("Frage hochladen")]),
                                _c(
                                  "div",
                                  { attrs: { role: "tablist" } },
                                  [
                                    _c(
                                      "b-card",
                                      {
                                        staticClass: "mb-1",
                                        attrs: { "no-body": "" },
                                      },
                                      [
                                        _c(
                                          "b-card-header",
                                          {
                                            attrs: {
                                              "header-tag": "header",
                                              role: "tab",
                                            },
                                          },
                                          [
                                            _c("h6", { staticClass: "m-0" }, [
                                              _c(
                                                "a",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-toggle",
                                                      rawName:
                                                        "v-b-toggle.accordion-51",
                                                      modifiers: {
                                                        "accordion-51": true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass: "text-dark",
                                                  attrs: {
                                                    href: "javascript: void(0);",
                                                  },
                                                },
                                                [_vm._v("Spalte auswählen")]
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "b-collapse",
                                          {
                                            attrs: {
                                              id: "accordion-51",
                                              visible: "",
                                              accordion: "my-accordion",
                                              role: "tabpanel",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card-body",
                                              [
                                                _c("b-card-text", [
                                                  _c("ul", [
                                                    _c("li", [
                                                      _vm._v(
                                                        " Wählen Sie die Spalte der gewünschten Frage aus. "
                                                      ),
                                                    ]),
                                                  ]),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-card",
                                      {
                                        staticClass: "mb-1",
                                        attrs: { "no-body": "" },
                                      },
                                      [
                                        _c(
                                          "b-card-header",
                                          {
                                            attrs: {
                                              "header-tag": "header",
                                              role: "tab",
                                            },
                                          },
                                          [
                                            _c("h6", { staticClass: "m-0" }, [
                                              _c(
                                                "a",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-toggle",
                                                      rawName:
                                                        "v-b-toggle.accordion-62",
                                                      modifiers: {
                                                        "accordion-62": true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass: "text-dark",
                                                  attrs: {
                                                    href: "javascript: void(0);",
                                                  },
                                                },
                                                [_vm._v("Daten bestimmen")]
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "b-collapse",
                                          {
                                            attrs: {
                                              id: "accordion-62",
                                              visible: "",
                                              accordion: "my-accordion",
                                              role: "tabpanel",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card-body",
                                              [
                                                _c("b-card-text", [
                                                  _c("ul", [
                                                    _c("li", [
                                                      _vm._v(
                                                        " Bestimmen Sie die Spalte mit “Frage”. "
                                                      ),
                                                    ]),
                                                  ]),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-card",
                                      {
                                        staticClass: "mb-1",
                                        attrs: { "no-body": "" },
                                      },
                                      [
                                        _c(
                                          "b-card-header",
                                          {
                                            attrs: {
                                              "header-tag": "header",
                                              role: "tab",
                                            },
                                          },
                                          [
                                            _c("h6", { staticClass: "m-0" }, [
                                              _c(
                                                "a",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-toggle",
                                                      rawName:
                                                        "v-b-toggle.accordion-63",
                                                      modifiers: {
                                                        "accordion-63": true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass: "text-dark",
                                                  attrs: {
                                                    href: "javascript: void(0);",
                                                  },
                                                },
                                                [_vm._v("Spaltenname")]
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "b-collapse",
                                          {
                                            attrs: {
                                              id: "accordion-63",
                                              visible: "",
                                              accordion: "my-accordion",
                                              role: "tabpanel",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card-body",
                                              [
                                                _c("b-card-text", [
                                                  _c("ul", [
                                                    _c("li", [
                                                      _vm._v(
                                                        " Sie können die Bezeichnung Ihrer Fragespalte beliebig ändern. "
                                                      ),
                                                    ]),
                                                    _c("li", [
                                                      _vm._v(
                                                        " Wenn Sie die Bezeichnung nicht ändern, wird die Bezeichnung aus dem Datenfile verwendet. "
                                                      ),
                                                    ]),
                                                  ]),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-card",
                                      {
                                        staticClass: "mb-1",
                                        attrs: { "no-body": "" },
                                      },
                                      [
                                        _c(
                                          "b-card-header",
                                          {
                                            attrs: {
                                              "header-tag": "header",
                                              role: "tab",
                                            },
                                          },
                                          [
                                            _c("h6", { staticClass: "m-0" }, [
                                              _c(
                                                "a",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-toggle",
                                                      rawName:
                                                        "v-b-toggle.accordion-65",
                                                      modifiers: {
                                                        "accordion-65": true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass: "text-dark",
                                                  attrs: {
                                                    href: "javascript: void(0);",
                                                  },
                                                },
                                                [_vm._v("Projektiv-Fragetyp ")]
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "b-collapse",
                                          {
                                            attrs: {
                                              id: "accordion-65",
                                              visible: "",
                                              accordion: "my-accordion",
                                              role: "tabpanel",
                                            },
                                          },
                                          [
                                            _c("b-card-body", [
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "col-md-12",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        { staticClass: "card" },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "card-body",
                                                            },
                                                            [
                                                              _c("label", [
                                                                _vm._v(
                                                                  " Vollständig "
                                                                ),
                                                              ]),
                                                              _c("p", [
                                                                _vm._v(
                                                                  " Genaue Verteilung der unterschiedlichen Antwortoptionen "
                                                                ),
                                                              ]),
                                                              _c("p", [
                                                                _vm._v(
                                                                  " Projektionstyp - Vollständig - Angaben "
                                                                ),
                                                              ]),
                                                              _c("ul", [
                                                                _c("li", [
                                                                  _vm._v(
                                                                    "Datenstruktur"
                                                                  ),
                                                                ]),
                                                                _c("ul", [
                                                                  _c("li", [
                                                                    _vm._v(
                                                                      " Die Antworten der Bezugsfrage dürfen sich nur in 1 Spalte befinden. Die einzelnen Projektionen müssen sich in einer eigenen Spalte befinden. "
                                                                    ),
                                                                    _c("br"),
                                                                    _vm._v(
                                                                      " Beispiel: Frage mit 4 Antwortoptionen = 5 Spalten "
                                                                    ),
                                                                  ]),
                                                                ]),
                                                                _c("li", [
                                                                  _vm._v(
                                                                    " Anzahl der projektiven Antwortoptionen "
                                                                  ),
                                                                ]),
                                                                _c("ul", [
                                                                  _c("li", [
                                                                    _vm._v(
                                                                      " Nach Angabe und Spezifizierung der Antwortoptionen, müssen Sie angeben wie viele Antwortoptionen projektiv erfasst wurden (projektive Spalten es für diese Frage im Datenfile). "
                                                                    ),
                                                                  ]),
                                                                ]),
                                                                _c("li", [
                                                                  _vm._v(
                                                                    " Spalte mit Projektionen auswählen "
                                                                  ),
                                                                ]),
                                                                _c("ul", [
                                                                  _c("li", [
                                                                    _vm._v(
                                                                      " Wählen Sie eine Spalte mit Projektionen, für eine Antwortoption aus dem Dropdown-Menü aus. "
                                                                    ),
                                                                  ]),
                                                                ]),
                                                                _c("li", [
                                                                  _vm._v(
                                                                    " Verknüpfte Antwortoption auswählen "
                                                                  ),
                                                                ]),
                                                                _c("ul", [
                                                                  _c("li", [
                                                                    _vm._v(
                                                                      " Geben Sie an, mit welcher Antwortoption die Projektionsspalte verknüpft werden soll und speichern Sie den Vorgang "
                                                                    ),
                                                                  ]),
                                                                ]),
                                                                _c("li", [
                                                                  _vm._v(
                                                                    " Führen Sie diesen Schritt für alle für alle Antwortoptionen mit Projektionsspalte durch und speichern Sie im Anschluss die Frage. "
                                                                  ),
                                                                ]),
                                                                _c("li", [
                                                                  _vm._v(
                                                                    " Antwortoptionen die keine projektive Spalte zugewiesen bekommen (z.B. Keine Antwort) haben, werden im Ergebnis nicht gewichtet. "
                                                                  ),
                                                                ]),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "col-md-12",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        { staticClass: "card" },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "card-body",
                                                            },
                                                            [
                                                              _c("label", [
                                                                _vm._v(
                                                                  " Durchschnitt "
                                                                ),
                                                              ]),
                                                              _c("p", [
                                                                _vm._v(
                                                                  " Durchschnittliche Antwort auf einer numerischen Skala "
                                                                ),
                                                              ]),
                                                              _c("p", [
                                                                _vm._v(
                                                                  " Projektionstyp - Durchschnitt - Angaben "
                                                                ),
                                                              ]),
                                                              _c("ul", [
                                                                _c("li", [
                                                                  _vm._v(
                                                                    "Datenstruktur"
                                                                  ),
                                                                ]),
                                                                _c("ul", [
                                                                  _c("li", [
                                                                    _vm._v(
                                                                      " Die Antworten der Bezugsfrage dürfen sich nur in 1 Spalte befinden. Die Projektionen müssen sich in einer zweiten Spalte befinden. "
                                                                    ),
                                                                    _c("br"),
                                                                    _vm._v(
                                                                      " Wichtig! Die Codierung der Antworten in der Fragespalte muss ident mit der Projektionsspalte sein. "
                                                                    ),
                                                                  ]),
                                                                ]),
                                                                _c("li", [
                                                                  _vm._v(
                                                                    "Beginn / Ende"
                                                                  ),
                                                                ]),
                                                                _c("ul", [
                                                                  _c("li", [
                                                                    _vm._v(
                                                                      " Da der Projektionstyp Durchschnitt nur für numerische Skalen verwendet werden kann, muss festgelegt werden bei welchen Werten diese beginnt und endet "
                                                                    ),
                                                                  ]),
                                                                  _c("li", [
                                                                    _vm._v(
                                                                      " Alle Werte außerhalb dieses Bereichs werden zwar erfasst, jedoch nicht für die Gewichtung berücksichtigt. "
                                                                    ),
                                                                  ]),
                                                                ]),
                                                                _c("li", [
                                                                  _vm._v(
                                                                    " Spalte mit Projektionen auswählen "
                                                                  ),
                                                                ]),
                                                                _c("ul", [
                                                                  _c("li", [
                                                                    _vm._v(
                                                                      " Wählen Sie die Spalte mit den Projektionen aus dem Dropdown-Menü aus und speichern Sie den Vorgang als auch die Frage. "
                                                                    ),
                                                                  ]),
                                                                ]),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "col-md-12",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        { staticClass: "card" },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "card-body",
                                                            },
                                                            [
                                                              _c("label", [
                                                                _vm._v(
                                                                  " Übereinstimmung "
                                                                ),
                                                              ]),
                                                              _c("p", [
                                                                _vm._v(
                                                                  " Prozentuelle Übereinstimmung mit eigener Antwort "
                                                                ),
                                                              ]),
                                                              _c("p", [
                                                                _vm._v(
                                                                  " Projektionstyp - Übereinstimmung - Angaben "
                                                                ),
                                                              ]),
                                                              _c("ul", [
                                                                _c("li", [
                                                                  _vm._v(
                                                                    "Datenstruktur"
                                                                  ),
                                                                ]),
                                                                _c("ul", [
                                                                  _c("li", [
                                                                    _vm._v(
                                                                      " Die Antworten der Bezugsfrage dürfen sich nur in 1 Spalte befinden. Die Projektionen müssen sich in einer zweiten Spalte befinden. "
                                                                    ),
                                                                  ]),
                                                                ]),
                                                                _c("li", [
                                                                  _vm._v(
                                                                    " Bei diesem Projektionstyp werden standardmäßig alle Antwortoptionen gewichtet. "
                                                                  ),
                                                                ]),
                                                                _c("li", [
                                                                  _vm._v(
                                                                    " Spalte mit Projektionen auswählen "
                                                                  ),
                                                                ]),
                                                                _c("ul", [
                                                                  _c("li", [
                                                                    _vm._v(
                                                                      " Wählen Sie die Spalte mit den Projektionen aus dem Dropdown-Menü aus und speichern Sie den Vorgang als auch die Frage. "
                                                                    ),
                                                                  ]),
                                                                ]),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "col-md-12",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        { staticClass: "card" },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "card-body",
                                                            },
                                                            [
                                                              _c("label", [
                                                                _vm._v(
                                                                  " Nicht-Projektive Fragen "
                                                                ),
                                                              ]),
                                                              _c("p", [
                                                                _vm._v(
                                                                  " Sie können auch Fragen, die keine zugehörige projektive Frage haben hochladen. "
                                                                ),
                                                              ]),
                                                              _c("p", [
                                                                _vm._v(
                                                                  " Diese Fragen haben keinen Einfluss auf die Berechnung der Redem-Scores - können aber durch Anwendung der Redem Gewichtung analysiert werden. "
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-card",
                                      {
                                        staticClass: "mb-1",
                                        attrs: { "no-body": "" },
                                      },
                                      [
                                        _c(
                                          "b-card-header",
                                          {
                                            attrs: {
                                              "header-tag": "header",
                                              role: "tab",
                                            },
                                          },
                                          [
                                            _c("h6", { staticClass: "m-0" }, [
                                              _c(
                                                "a",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-toggle",
                                                      rawName:
                                                        "v-b-toggle.accordion-54",
                                                      modifiers: {
                                                        "accordion-54": true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass: "text-dark",
                                                  attrs: {
                                                    href: "javascript: void(0);",
                                                  },
                                                },
                                                [_vm._v("Themenkategorie ")]
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "b-collapse",
                                          {
                                            attrs: {
                                              id: "accordion-54",
                                              visible: "",
                                              accordion: "my-accordion",
                                              role: "tabpanel",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card-body",
                                              [
                                                _c("b-card-text", [
                                                  _c("ul", [
                                                    _c("li", [
                                                      _vm._v(
                                                        " Wählen Sie aus der folgenden Kategorien aus, die für Ihre Frage zutrifft "
                                                      ),
                                                    ]),
                                                    _c("ul", [
                                                      _c("li", [
                                                        _vm._v("Kaufabsichten"),
                                                      ]),
                                                      _c("li", [
                                                        _vm._v(
                                                          "Konsumforschung"
                                                        ),
                                                      ]),
                                                      _c("li", [
                                                        _vm._v(
                                                          "Markenwarnehmung"
                                                        ),
                                                      ]),
                                                      _c("li", [
                                                        _vm._v(
                                                          "Politikforschung"
                                                        ),
                                                      ]),
                                                      _c("li", [
                                                        _vm._v(
                                                          "Sozialforschung"
                                                        ),
                                                      ]),
                                                      _c("li", [
                                                        _vm._v(
                                                          "Kundenzufriedenheit"
                                                        ),
                                                      ]),
                                                      _c("ul", [
                                                        _c("li", [
                                                          _vm._v(
                                                            " Bestimmen Sie ob es sich bei Ihrer Frage um eine Frage nach “NPS - Net Promoter Score” Format handelt "
                                                          ),
                                                        ]),
                                                      ]),
                                                      _c("li", [
                                                        _vm._v(
                                                          "Mitarbeiterzufriedenheit"
                                                        ),
                                                      ]),
                                                      _c("li", [
                                                        _vm._v("Sonstige"),
                                                      ]),
                                                      _c("ul", [
                                                        _c("li", [
                                                          _vm._v(
                                                            " Sie können im Textfeld angeben um welche Kategorie es sich handelt. "
                                                          ),
                                                        ]),
                                                      ]),
                                                    ]),
                                                    _c("li", [
                                                      _vm._v(
                                                        "Diese Angabe ist optional"
                                                      ),
                                                    ]),
                                                  ]),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-card",
                                      {
                                        staticClass: "mb-1",
                                        attrs: { "no-body": "" },
                                      },
                                      [
                                        _c(
                                          "b-card-header",
                                          {
                                            attrs: {
                                              "header-tag": "header",
                                              role: "tab",
                                            },
                                          },
                                          [
                                            _c("h6", { staticClass: "m-0" }, [
                                              _c(
                                                "a",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-toggle",
                                                      rawName:
                                                        "v-b-toggle.accordion-67",
                                                      modifiers: {
                                                        "accordion-67": true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass: "text-dark",
                                                  attrs: {
                                                    href: "javascript: void(0);",
                                                  },
                                                },
                                                [_vm._v("Inhaltlicher Fokus ")]
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "b-collapse",
                                          {
                                            attrs: {
                                              id: "accordion-67",
                                              visible: "",
                                              accordion: "my-accordion",
                                              role: "tabpanel",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card-body",
                                              [
                                                _c("b-card-text", [
                                                  _c("ul", [
                                                    _c("li", [
                                                      _vm._v(
                                                        " Geben Sie an welche Inhaltliche Ausrichtung Ihre Frage aufweist. Wählen Sie unter einer der folgenden Möglichkeiten aus. "
                                                      ),
                                                    ]),
                                                    _c("ul", [
                                                      _c("li", [
                                                        _vm._v("Meinung"),
                                                      ]),
                                                      _c("li", [
                                                        _vm._v("Verhalten"),
                                                      ]),
                                                      _c("li", [
                                                        _vm._v("Wissen"),
                                                      ]),
                                                    ]),
                                                    _c("li", [
                                                      _vm._v(
                                                        " Die Angabe des inhaltlichen Fokus ist nur für projektive Fragen notwendig. "
                                                      ),
                                                    ]),
                                                    _c("li", [
                                                      _vm._v(
                                                        "Diese Angabe ist optional"
                                                      ),
                                                    ]),
                                                  ]),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-card",
                                      {
                                        staticClass: "mb-1",
                                        attrs: { "no-body": "" },
                                      },
                                      [
                                        _c(
                                          "b-card-header",
                                          {
                                            attrs: {
                                              "header-tag": "header",
                                              role: "tab",
                                            },
                                          },
                                          [
                                            _c("h6", { staticClass: "m-0" }, [
                                              _c(
                                                "a",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-toggle",
                                                      rawName:
                                                        "v-b-toggle.accordion-68",
                                                      modifiers: {
                                                        "accordion-68": true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass: "text-dark",
                                                  attrs: {
                                                    href: "javascript: void(0);",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "Frage zeitlich bezogen auf "
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "b-collapse",
                                          {
                                            attrs: {
                                              id: "accordion-68",
                                              visible: "",
                                              accordion: "my-accordion",
                                              role: "tabpanel",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card-body",
                                              [
                                                _c("b-card-text", [
                                                  _c("ul", [
                                                    _c("li", [
                                                      _vm._v(
                                                        " Auf welche zeitliche Ebene bezieht sich Ihre Frage? "
                                                      ),
                                                    ]),
                                                    _c("ul", [
                                                      _c("li", [
                                                        _vm._v("Vergangenheit"),
                                                      ]),
                                                      _c("li", [
                                                        _vm._v("Gegenwart"),
                                                      ]),
                                                      _c("li", [
                                                        _vm._v("Zukunft"),
                                                      ]),
                                                    ]),
                                                    _c("li", [
                                                      _vm._v(
                                                        "Diese Angabe ist optional"
                                                      ),
                                                    ]),
                                                  ]),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-card",
                                      {
                                        staticClass: "mb-1",
                                        attrs: { "no-body": "" },
                                      },
                                      [
                                        _c(
                                          "b-card-header",
                                          {
                                            attrs: {
                                              "header-tag": "header",
                                              role: "tab",
                                            },
                                          },
                                          [
                                            _c("h6", { staticClass: "m-0" }, [
                                              _c(
                                                "a",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-toggle",
                                                      rawName:
                                                        "v-b-toggle.accordion-69",
                                                      modifiers: {
                                                        "accordion-69": true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass: "text-dark",
                                                  attrs: {
                                                    href: "javascript: void(0);",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "Inhaltliche Effekte (Mehrfachauswahl möglich) "
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "b-collapse",
                                          {
                                            attrs: {
                                              id: "accordion-69",
                                              visible: "",
                                              accordion: "my-accordion",
                                              role: "tabpanel",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card-body",
                                              [
                                                _c("b-card-text", [
                                                  _c("ul", [
                                                    _c("li", [
                                                      _vm._v(
                                                        " Geben Sie bitte an, ob einer oder mehrere dieser inhaltlichen Effekte auf Ihre Frage zutreffen könnten "
                                                      ),
                                                    ]),
                                                    _c("ul", [
                                                      _c("li", [
                                                        _vm._v(
                                                          " Heikle Frage (Soziale Erwünschtheit) "
                                                        ),
                                                      ]),
                                                      _c("li", [
                                                        _vm._v(
                                                          " Frage ist eine Aussage mit Übereinstimmungsskala "
                                                        ),
                                                      ]),
                                                      _c("li", [
                                                        _vm._v(
                                                          " Informationsvorsprung einzelner Befragter "
                                                        ),
                                                      ]),
                                                    ]),
                                                    _c("li", [
                                                      _vm._v(
                                                        "Diese Angabe ist optional"
                                                      ),
                                                    ]),
                                                  ]),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-card",
                                      {
                                        staticClass: "mb-1",
                                        attrs: { "no-body": "" },
                                      },
                                      [
                                        _c(
                                          "b-card-header",
                                          {
                                            attrs: {
                                              "header-tag": "header",
                                              role: "tab",
                                            },
                                          },
                                          [
                                            _c("h6", { staticClass: "m-0" }, [
                                              _c(
                                                "a",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-toggle",
                                                      rawName:
                                                        "v-b-toggle.accordion-66",
                                                      modifiers: {
                                                        "accordion-66": true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass: "text-dark",
                                                  attrs: {
                                                    href: "javascript: void(0);",
                                                  },
                                                },
                                                [_vm._v("Antwortoptionen ")]
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "b-collapse",
                                          {
                                            attrs: {
                                              id: "accordion-66",
                                              visible: "",
                                              accordion: "my-accordion",
                                              role: "tabpanel",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card-body",
                                              [
                                                _c("b-card-text", [
                                                  _c("ul", [
                                                    _c("li", [
                                                      _vm._v(
                                                        " Hier finden Sie alle Antwortoptionen, die aus der Fragespalte ausgelesen wurden. "
                                                      ),
                                                    ]),
                                                    _c("li", [
                                                      _vm._v(
                                                        " Sie können die Bezeichnung beliebig ändern. Standardmäßig wird die ausgelesene Bezeichnung verwendet. "
                                                      ),
                                                    ]),
                                                    _c("li", [
                                                      _vm._v(
                                                        " Weiters kann die Reihenfolge der Antwortoption geändert werden. "
                                                      ),
                                                    ]),
                                                    _c("li", [
                                                      _vm._v(
                                                        " Es gibt auch die Möglichkeit, eine Antwortoption von der Gewichtung auszuschließen. Das kann bei Antwortoptionen wie zum Beispiel, “Weiß nicht” oder “Keine Antwort” sinnvoll sein. "
                                                      ),
                                                    ]),
                                                    _c("li", [
                                                      _vm._v(
                                                        " Bei Bedarf können Sie auch Antwortoptionen hinzufügen, die keine Stimmen erhalten haben und dadurch nicht ausgelesen werden konnten. Dadurch lassen sich alle Antwortoptionen aus Ihrem Fragebogen abbilden. "
                                                      ),
                                                    ]),
                                                  ]),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-card",
                                      {
                                        staticClass: "mb-1",
                                        attrs: { "no-body": "" },
                                      },
                                      [
                                        _c(
                                          "b-card-header",
                                          {
                                            attrs: {
                                              "header-tag": "header",
                                              role: "tab",
                                            },
                                          },
                                          [
                                            _c("h6", { staticClass: "m-0" }, [
                                              _c(
                                                "a",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-toggle",
                                                      rawName:
                                                        "v-b-toggle.accordion-73",
                                                      modifiers: {
                                                        "accordion-73": true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass: "text-dark",
                                                  staticStyle: {
                                                    cursor: "default",
                                                  },
                                                  attrs: {
                                                    href: "javascript: void(0);",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "Speichern Sie den Vorgang"
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "col-md-12 mb-5" }, [
                                _c("h6", { staticClass: "mb-3" }, [
                                  _vm._v("Filtervariable hochladen"),
                                ]),
                                _c(
                                  "div",
                                  { attrs: { role: "tablist" } },
                                  [
                                    _c(
                                      "b-card",
                                      {
                                        staticClass: "mb-1",
                                        attrs: { "no-body": "" },
                                      },
                                      [
                                        _c(
                                          "b-card-header",
                                          {
                                            attrs: {
                                              "header-tag": "header",
                                              role: "tab",
                                            },
                                          },
                                          [
                                            _c("h6", { staticClass: "m-0" }, [
                                              _c(
                                                "a",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-toggle",
                                                      rawName:
                                                        "v-b-toggle.accordion-61",
                                                      modifiers: {
                                                        "accordion-61": true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass: "text-dark",
                                                  attrs: {
                                                    href: "javascript: void(0);",
                                                  },
                                                },
                                                [_vm._v("Spalte auswählen")]
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "b-collapse",
                                          {
                                            attrs: {
                                              id: "accordion-61",
                                              visible: "",
                                              accordion: "my-accordion",
                                              role: "tabpanel",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card-body",
                                              [
                                                _c("b-card-text", [
                                                  _c("ul", [
                                                    _c("li", [
                                                      _vm._v(
                                                        " Wählen Sie die Spalte Ihrer Filtervariable aus dem Datenfile aus. "
                                                      ),
                                                    ]),
                                                  ]),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-card",
                                      {
                                        staticClass: "mb-1",
                                        attrs: { "no-body": "" },
                                      },
                                      [
                                        _c(
                                          "b-card-header",
                                          {
                                            attrs: {
                                              "header-tag": "header",
                                              role: "tab",
                                            },
                                          },
                                          [
                                            _c("h6", { staticClass: "m-0" }, [
                                              _c(
                                                "a",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-toggle",
                                                      rawName:
                                                        "v-b-toggle.accordion-62",
                                                      modifiers: {
                                                        "accordion-62": true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass: "text-dark",
                                                  attrs: {
                                                    href: "javascript: void(0);",
                                                  },
                                                },
                                                [_vm._v("Daten bestimmen")]
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "b-collapse",
                                          {
                                            attrs: {
                                              id: "accordion-62",
                                              visible: "",
                                              accordion: "my-accordion",
                                              role: "tabpanel",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card-body",
                                              [
                                                _c("b-card-text", [
                                                  _c("ul", [
                                                    _c("li", [
                                                      _vm._v(
                                                        " Bestimmen Sie die Spalte mit “Filtervariable”. "
                                                      ),
                                                    ]),
                                                  ]),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-card",
                                      {
                                        staticClass: "mb-1",
                                        attrs: { "no-body": "" },
                                      },
                                      [
                                        _c(
                                          "b-card-header",
                                          {
                                            attrs: {
                                              "header-tag": "header",
                                              role: "tab",
                                            },
                                          },
                                          [
                                            _c("h6", { staticClass: "m-0" }, [
                                              _c(
                                                "a",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-toggle",
                                                      rawName:
                                                        "v-b-toggle.accordion-63",
                                                      modifiers: {
                                                        "accordion-63": true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass: "text-dark",
                                                  attrs: {
                                                    href: "javascript: void(0);",
                                                  },
                                                },
                                                [_vm._v("Spaltenname")]
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "b-collapse",
                                          {
                                            attrs: {
                                              id: "accordion-63",
                                              visible: "",
                                              accordion: "my-accordion",
                                              role: "tabpanel",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card-body",
                                              [
                                                _c("b-card-text", [
                                                  _c("ul", [
                                                    _c("li", [
                                                      _vm._v(
                                                        " Sie können die Bezeichnung Ihrer Filtervariable beliebig ändern. "
                                                      ),
                                                    ]),
                                                    _c("li", [
                                                      _vm._v(
                                                        " Wenn Sie die Bezeichnung nicht ändern, wird die Bezeichnung aus dem Datenfile verwendet. "
                                                      ),
                                                    ]),
                                                  ]),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-card",
                                      {
                                        staticClass: "mb-1",
                                        attrs: { "no-body": "" },
                                      },
                                      [
                                        _c(
                                          "b-card-header",
                                          {
                                            attrs: {
                                              "header-tag": "header",
                                              role: "tab",
                                            },
                                          },
                                          [
                                            _c("h6", { staticClass: "m-0" }, [
                                              _c(
                                                "a",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-toggle",
                                                      rawName:
                                                        "v-b-toggle.accordion-64",
                                                      modifiers: {
                                                        "accordion-64": true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass: "text-dark",
                                                  attrs: {
                                                    href: "javascript: void(0);",
                                                  },
                                                },
                                                [_vm._v("Filteroptionen ")]
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "b-collapse",
                                          {
                                            attrs: {
                                              id: "accordion-64",
                                              visible: "",
                                              accordion: "my-accordion",
                                              role: "tabpanel",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card-body",
                                              [
                                                _c("b-card-text", [
                                                  _c("ul", [
                                                    _c("li", [
                                                      _vm._v(
                                                        " Alle Filteroptionen werden aus der ausgewählten Spalte ausgelesen. "
                                                      ),
                                                    ]),
                                                    _c("li", [
                                                      _vm._v(
                                                        " Sie können die Bezeichnungen der Filteroptionen ändern. Auch hier wird standardmäßig die ausgelesene Bezeichnung verwendet. "
                                                      ),
                                                    ]),
                                                    _c("li", [
                                                      _vm._v(
                                                        " Bestätigen Sie Ihre Eingaben, indem Sie auf die Schaltfläche “Filtervariable speichern” klicken. "
                                                      ),
                                                    ]),
                                                  ]),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-card",
                                      {
                                        staticClass: "mb-1",
                                        attrs: { "no-body": "" },
                                      },
                                      [
                                        _c(
                                          "b-card-header",
                                          {
                                            attrs: {
                                              "header-tag": "header",
                                              role: "tab",
                                            },
                                          },
                                          [
                                            _c("h6", { staticClass: "m-0" }, [
                                              _c(
                                                "a",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-toggle",
                                                      rawName:
                                                        "v-b-toggle.accordion-73",
                                                      modifiers: {
                                                        "accordion-73": true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass: "text-dark",
                                                  staticStyle: {
                                                    cursor: "default",
                                                  },
                                                  attrs: {
                                                    href: "javascript: void(0);",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "Speichern Sie den Vorgang"
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "col-md-12 mb-5" }, [
                                _c("h6", { staticClass: "mb-3" }, [
                                  _vm._v(
                                    " Zusätzlichen Gewichtungsfaktor hochladen "
                                  ),
                                ]),
                                _c(
                                  "div",
                                  { attrs: { role: "tablist" } },
                                  [
                                    _c(
                                      "b-card",
                                      {
                                        staticClass: "mb-1",
                                        attrs: { "no-body": "" },
                                      },
                                      [
                                        _c(
                                          "b-card-header",
                                          {
                                            attrs: {
                                              "header-tag": "header",
                                              role: "tab",
                                            },
                                          },
                                          [
                                            _c("h6", { staticClass: "m-0" }, [
                                              _c(
                                                "a",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-toggle",
                                                      rawName:
                                                        "v-b-toggle.accordion-71",
                                                      modifiers: {
                                                        "accordion-71": true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass: "text-dark",
                                                  attrs: {
                                                    href: "javascript: void(0);",
                                                  },
                                                },
                                                [_vm._v("Spalte auswählen")]
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "b-collapse",
                                          {
                                            attrs: {
                                              id: "accordion-71",
                                              visible: "",
                                              accordion: "my-accordion",
                                              role: "tabpanel",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card-body",
                                              [
                                                _c("b-card-text", [
                                                  _c("ul", [
                                                    _c("li", [
                                                      _vm._v(
                                                        " Wählen Sie die Spalte, in der sich der zusätzliche Gewichtungsfaktor befindet, aus. "
                                                      ),
                                                    ]),
                                                  ]),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-card",
                                      {
                                        staticClass: "mb-1",
                                        attrs: { "no-body": "" },
                                      },
                                      [
                                        _c(
                                          "b-card-header",
                                          {
                                            attrs: {
                                              "header-tag": "header",
                                              role: "tab",
                                            },
                                          },
                                          [
                                            _c("h6", { staticClass: "m-0" }, [
                                              _c(
                                                "a",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-toggle",
                                                      rawName:
                                                        "v-b-toggle.accordion-72",
                                                      modifiers: {
                                                        "accordion-72": true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass: "text-dark",
                                                  attrs: {
                                                    href: "javascript: void(0);",
                                                  },
                                                },
                                                [_vm._v("Daten bestimmen")]
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "b-collapse",
                                          {
                                            attrs: {
                                              id: "accordion-72",
                                              visible: "",
                                              accordion: "my-accordion",
                                              role: "tabpanel",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card-body",
                                              [
                                                _c("b-card-text", [
                                                  _c("ul", [
                                                    _c("li", [
                                                      _vm._v(
                                                        " Bestimmen Sie die Spalte mit “Zusätzlicher Gewichtungsfaktor. "
                                                      ),
                                                    ]),
                                                  ]),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-card",
                                      {
                                        staticClass: "mb-1",
                                        attrs: { "no-body": "" },
                                      },
                                      [
                                        _c(
                                          "b-card-header",
                                          {
                                            attrs: {
                                              "header-tag": "header",
                                              role: "tab",
                                            },
                                          },
                                          [
                                            _c("h6", { staticClass: "m-0" }, [
                                              _c(
                                                "a",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-toggle",
                                                      rawName:
                                                        "v-b-toggle.accordion-73",
                                                      modifiers: {
                                                        "accordion-73": true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass: "text-dark",
                                                  staticStyle: {
                                                    cursor: "default",
                                                  },
                                                  attrs: {
                                                    href: "javascript: void(0);",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "Speichern Sie den Vorgang"
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                    _c(
                      "b-tab",
                      {
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "d-inline-block d-sm-none" },
                                  [
                                    _c("i", {
                                      staticClass: "mdi mdi-numeric-3-circle",
                                    }),
                                  ]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-inline-block" },
                                  [_vm._v("Schritt 3")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        _c("div", { staticClass: "row mt-3" }, [
                          _c("div", { staticClass: "col-xl-12" }, [
                            _c("ul", [
                              _c("li", [
                                _vm._v(
                                  " Im letzten Schritt erhalten Sie eine Zusammenfassung über alle Angaben. "
                                ),
                              ]),
                              _c("li", { staticClass: "mt-3" }, [
                                _vm._v(
                                  " Kontrollieren Sie diese Angaben bitte genau. "
                                ),
                                _c("ul", [
                                  _c("li", [
                                    _vm._v(
                                      " Sie können jederzeit wieder zu Schritt 2 und 1 zurückkehren um Ihre Angaben zu ändern. "
                                    ),
                                  ]),
                                ]),
                              ]),
                              _c("li", { staticClass: "mt-3" }, [
                                _vm._v(
                                  " Weiters finden Sie die Berechnung über die benötigten Credits, die für das Hochladen Ihres Datenfiles notwendig sind. "
                                ),
                              ]),
                              _c("li", { staticClass: "mt-3" }, [
                                _vm._v(
                                  " Bestätigen Sie Ihre Eingaben, indem Sie die auf Schaltfläche “Bestätigen und Hochladen” klicken. "
                                ),
                              ]),
                              _c("li", { staticClass: "mt-3" }, [
                                _vm._v(
                                  " Sollte beim Hochladen des Datenfiles ein Fehler auftreten - kontrollieren Sie bitte Ihre Angaben. Sollte der Fehler erneut auftreten - kontaktieren Sie bitte unseren Support unter folgender Email: support@redem.io "
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }