<script>
import Layout from "@/router/layouts/main";
import PageHeader from "@/shared/components/page-header";
import appConfig from "@/app.config";

/**
 * Upload Guide
 */
export default {
  page: {
    title: "Guide für Upload Prozess",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Guide für Upload Prozess",
      items: [
        {
          text: "Dashbaord",
          href: "/"
        },
        {
          text: "Guides",
          href: "/guides"
        },
        {
          text: "Upload Guides",
          active: true
        }
      ]
    };
  },
  methods: {
    clickOnUploadGuide() {}
  }
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <b-tabs content-class="p-3 text-muted">
              <!-- Step 1-->
              <b-tab active class="border-0">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="mdi mdi-numeric-1-circle"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Schritt 1</span>
                </template>
                <div class="row mt-3">
                  <div class="col-xl-12">
                    <div role="tablist">
                      <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" role="tab">
                          <h6 class="m-0">
                            <a
                              v-b-toggle.accordion-11
                              class="text-dark"
                              href="javascript: void(0);"
                              >Name</a
                            >
                          </h6>
                        </b-card-header>
                        <b-collapse
                          id="accordion-11"
                          visible
                          accordion="my-accordion"
                          role="tabpanel"
                        >
                          <b-card-body>
                            <b-card-text>
                              <ul>
                                <li>
                                  Geben Sie einen beliebigen Namen für Ihr
                                  Datenfile ein.
                                </li>
                                <li>Maximal 100. Zeichen</li>
                              </ul>
                              <div class="row mt-4">
                                <div class="col-md-6">
                                  <!-- <img
                                    src="@/assets/images/guide/step1-Name.jpg"
                                    class="img-fluid"
                                  /> -->
                                </div>
                              </div>
                            </b-card-text>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </div>
                  </div>
                  <div class="col-xl-12">
                    <div role="tablist">
                      <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" role="tab">
                          <h6 class="m-0">
                            <a
                              v-b-toggle.accordion-12
                              class="text-dark"
                              href="javascript: void(0);"
                              >Beschreibung</a
                            >
                          </h6>
                        </b-card-header>
                        <b-collapse
                          id="accordion-12"
                          visible
                          accordion="my-accordion"
                          role="tabpanel"
                        >
                          <b-card-body>
                            <b-card-text>
                              <ul>
                                <li>
                                  Sie können optional eine zusätzliche
                                  Beschreibung hinzufügen.
                                </li>
                                <li>Maximal 500 Zeichen</li>
                              </ul>
                              <div class="row mt-4">
                                <div class="col-md-6">
                                  <!-- <img
                                    src="@/assets/images/guide/step1-Description.jpg"
                                    class="img-fluid"
                                  /> -->
                                </div>
                              </div>
                            </b-card-text>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </div>
                  </div>
                  <div class="col-xl-12">
                    <div role="tablist">
                      <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" role="tab">
                          <h6 class="m-0">
                            <a
                              v-b-toggle.accordion-13
                              class="text-dark"
                              href="javascript: void(0);"
                              >Erhebungsmethode</a
                            >
                          </h6>
                        </b-card-header>
                        <b-collapse
                          id="accordion-13"
                          visible
                          accordion="my-accordion"
                          role="tabpanel"
                        >
                          <b-card-body>
                            <b-card-text>
                              <ul>
                                <li>
                                  Geben Sie die Erhebungsmethode/n an, mit der
                                  Sie die Daten erhoben haben.
                                </li>
                                <li>
                                  Wählen Sie zwischen folgenden Methoden
                                  <ul>
                                    <li>
                                      CAWI - Computer Assisted Web Interviews
                                    </li>
                                    <li>
                                      CATI - Computer Assisted Telephone
                                      Interviews
                                    </li>
                                    <li>
                                      CAPI - Computer Assisted Personal
                                      Interviews
                                    </li>
                                    <li>
                                      PAPI - Paper Assisted Personal Interviews
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  Bei Mixed-Mode Studien können Sie mehrere
                                  Items auswählen.
                                </li>
                              </ul>
                              <div class="row mt-4">
                                <div class="col-md-6">
                                  <!-- <img
                                    src="@/assets/images/guide/step1-Description.jpg"
                                    class="img-fluid"
                                  /> -->
                                </div>
                              </div>
                            </b-card-text>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </div>
                  </div>
                  <div class="col-xl-12">
                    <div role="tablist">
                      <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" role="tab">
                          <h6 class="m-0">
                            <a
                              v-b-toggle.accordion-14
                              class="text-dark"
                              href="javascript: void(0);"
                              >Dateiformat und Struktur</a
                            >
                          </h6>
                        </b-card-header>
                        <b-collapse
                          id="accordion-14"
                          visible
                          accordion="my-accordion"
                          role="tabpanel"
                        >
                          <b-card-body>
                            <b-card-text>
                              <ul>
                                <li>.xlsx Format (Microsoft Excel)</li>
                                <li>
                                  Stellen Sie sicher, dass sich alle Daten auf
                                  einem Tabellenblatt befinden. Sollte das
                                  Datenfile mehrere Tabellenblätter enthalten
                                  kann nur das erste Tabellenblatt verwendet
                                  werden.
                                </li>
                                <li>
                                  Zeilen: Datensätze müssen auf Zeilenebene
                                  spezifiziert sein (1 Datensatz - 1 Zeile)
                                </li>
                                <li>
                                  Spalten: Fragen, Filter, und andere
                                  Befragungsdaten müssen auf Spaltenebene
                                  spezifiziert sein.
                                </li>
                                <li>
                                  Projektionsspalten dürfen nur numerische Werte
                                  ohne Komma enthalten (z.B. 25, 96, 40,...)
                                </li>
                                <li>
                                  Nach Ende des letzten Datensatzes muss die
                                  Tabelle leer sein und darf keine weiteren
                                  Werte enthalten.
                                </li>
                                <li>
                                  Abhängig von Ihrem Browser, dürfen Datenfiles
                                  nur eine begrenzte Anzahl an Zeichen
                                  enthalten.
                                  <ul>
                                    <li>Chrome: 5242878 Zeichen</li>
                                    <li>Firefox: 5242883 Zeichen</li>
                                    <li>Edge: 122070 Zeichen</li>
                                  </ul>
                                </li>
                              </ul>
                              <div class="row mt-4">
                                <div class="col-md-6">
                                  <!-- <img
                                    src="@/assets/images/guide/step1-Description.jpg"
                                    class="img-fluid"
                                  /> -->
                                </div>
                              </div>
                            </b-card-text>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </div>
                  </div>
                  <div class="col-xl-12">
                    <div role="tablist">
                      <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" role="tab">
                          <h6 class="m-0">
                            <a
                              v-b-toggle.accordion-15
                              class="text-dark"
                              href="javascript: void(0);"
                              >Spaltenüberschriften</a
                            >
                          </h6>
                        </b-card-header>
                        <b-collapse
                          id="accordion-15"
                          visible
                          accordion="my-accordion"
                          role="tabpanel"
                        >
                          <b-card-body>
                            <b-card-text>
                              <ul>
                                <li>
                                  Geben Sie an, in welcher Zeile sich die
                                  Spaltenüberschriften beziehungsweise die
                                  Spaltenbezeichnungen befinden.
                                </li>
                              </ul>
                              <div class="row mt-4">
                                <div class="col-md-6">
                                  <!-- <img
                                    src="@/assets/images/guide/step1-Description.jpg"
                                    class="img-fluid"
                                  /> -->
                                </div>
                              </div>
                            </b-card-text>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </div>
                  </div>
                  <div class="col-xl-12">
                    <div role="tablist">
                      <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" role="tab">
                          <h6 class="m-0">
                            <a
                              v-b-toggle.accordion-16
                              class="text-dark"
                              href="javascript: void(0);"
                              >Erster Datensatz</a
                            >
                          </h6>
                        </b-card-header>
                        <b-collapse
                          id="accordion-16"
                          visible
                          accordion="my-accordion"
                          role="tabpanel"
                        >
                          <b-card-body>
                            <b-card-text>
                              <ul>
                                <li>
                                  Definieren Sie, in welcher Zeile sich der
                                  erste Ihrer Datensätze / Befragten befindet.
                                </li>
                              </ul>
                              <div class="row mt-4">
                                <div class="col-md-6">
                                  <!-- <img
                                    src="@/assets/images/guide/step1-Description.jpg"
                                    class="img-fluid"
                                  /> -->
                                </div>
                              </div>
                            </b-card-text>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </div>
                  </div>
                </div>
              </b-tab>
              <!-- Step 2 -->
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="mdi mdi-numeric-2-circle"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Schritt 2</span>
                </template>
                <div class="row mt-3">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-12">
                        <ul>
                          <li>
                            Im zweiten Schritt bestimmen Sie nun die Daten in
                            Ihrem Datenfile, welche für die Analyse durch Redem
                            relevant sind.
                          </li>
                          <li>
                            Um eine neue Spalte hinzufügen zu können, müssen Sie
                            die grüne Schaltfläche “Neue Spalte hinzufügen”
                            klicken.
                          </li>
                          <li>
                            Beachten Sie, dass bevor Sie eine neue Spalte
                            hinzufügen können, die vorherige Spalte bereits
                            fertig spezifiziert sein muss. Das heißt, es können
                            nicht mehrere Spalten gleichzeitig hinzugefügt
                            werden.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="row mt-4">
                      <div class="col-xl-12">
                        <div role="tablist">
                          <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" role="tab">
                              <h6 class="m-0">
                                <a
                                  v-b-toggle.accordion-1
                                  class="text-dark"
                                  href="javascript: void(0);"
                                  >Spalte auswählen</a
                                >
                              </h6>
                            </b-card-header>
                            <b-collapse
                              id="accordion-1"
                              visible
                              accordion="my-accordion"
                              role="tabpanel"
                            >
                              <b-card-body>
                                <b-card-text>
                                  <ul>
                                    <li>
                                      Wählen Sie aus, welche Spalte Sie
                                      spezifizieren möchten.
                                    </li>
                                    <li>
                                      Es werden alle Spalten mit den
                                      Bezeichnungen aus dem Datenfile in einem
                                      Dropdown-Menü angezeigt.
                                    </li>
                                    <li>
                                      Sie können auch nach dem Namen der
                                      gewünschten Spalte suchen.
                                    </li>
                                  </ul>
                                </b-card-text>
                              </b-card-body>
                            </b-collapse>
                          </b-card>
                        </div>
                      </div>
                      <div class="col-xl-12">
                        <div role="tablist">
                          <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" role="tab">
                              <h6 class="m-0">
                                <a
                                  v-b-toggle.accordion-2
                                  class="text-dark"
                                  href="javascript: void(0);"
                                  >Daten bestimmen</a
                                >
                              </h6>
                            </b-card-header>
                            <b-collapse
                              id="accordion-2"
                              accordion="my-accordion"
                              role="tabpanel"
                            >
                              <b-card-body>
                                <b-card-text>
                                  <ul>
                                    <li>
                                      Bestimmen Sie, um welche Art von Daten es
                                      sich handelt.
                                    </li>
                                    <li>
                                      Sie können zwischen folgenden
                                      Datenkategorien unterscheiden:
                                      <ul>
                                        <li>Datensatz-ID</li>
                                        <li>Frage</li>
                                        <li>Filtervariable</li>
                                        <li>Zusätzlicher Gewichtungsfaktor</li>
                                      </ul>
                                    </li>
                                  </ul>
                                </b-card-text>
                              </b-card-body>
                            </b-collapse>
                          </b-card>
                        </div>
                      </div>
                      <div class="col-xl-12">
                        <div role="tablist">
                          <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" role="tab">
                              <h6 class="m-0">
                                <a
                                  v-b-toggle.accordion-7
                                  class="text-dark"
                                  href="javascript: void(0);"
                                  >Angaben bearbeiten und löschen</a
                                >
                              </h6>
                            </b-card-header>
                            <b-collapse
                              id="accordion-7"
                              accordion="my-accordion"
                              role="tabpanel"
                            >
                              <b-card-body>
                                <b-card-text>
                                  <ul>
                                    <li>
                                      Sie können durch Klicken des
                                      “Bearbeiten-Icons”, Ihre Angaben jederzeit
                                      bearbeiten. Wenn Sie im Bearbeitungsmodus
                                      auf das “Löschen-Icon” klicken, werden die
                                      Angaben in dieser Spalte entfernt.
                                    </li>
                                  </ul>
                                </b-card-text>
                              </b-card-body>
                            </b-collapse>
                          </b-card>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-5">
                      <div class="col-md-12 mb-5">
                        <h6 class="mb-3">Datensatz-ID hochladen</h6>
                        <div role="tablist">
                          <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" role="tab">
                              <h6 class="m-0">
                                <a
                                  v-b-toggle.accordion-41
                                  class="text-dark"
                                  href="javascript: void(0);"
                                  >Spalte auswählen</a
                                >
                              </h6>
                            </b-card-header>
                            <b-collapse
                              id="accordion-41"
                              visible
                              accordion="my-accordion"
                              role="tabpanel"
                            >
                              <b-card-body>
                                <b-card-text>
                                  <ul>
                                    <li>
                                      Wählen Sie aus, welche Spalte Sie
                                      spezifizieren möchten.
                                    </li>
                                  </ul>
                                </b-card-text>
                              </b-card-body>
                            </b-collapse>
                          </b-card>
                          <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" role="tab">
                              <h6 class="m-0">
                                <a
                                  v-b-toggle.accordion-42
                                  class="text-dark"
                                  href="javascript: void(0);"
                                  >Daten bestimmen</a
                                >
                              </h6>
                            </b-card-header>
                            <b-collapse
                              id="accordion-42"
                              visible
                              accordion="my-accordion"
                              role="tabpanel"
                            >
                              <b-card-body>
                                <b-card-text>
                                  <ul>
                                    <li>
                                      Bestimmen Sie die Spalte mit
                                      “Datensatz-ID”.
                                    </li>
                                  </ul>
                                </b-card-text>
                              </b-card-body>
                            </b-collapse>
                          </b-card>
                          <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" role="tab">
                              <h6 class="m-0">
                                <a
                                  v-b-toggle.accordion-73
                                  href="javascript: void(0);"
                                  class="text-dark"
                                  style="cursor: default"
                                  >Speichern Sie den Vorgang</a
                                >
                              </h6>
                            </b-card-header>
                          </b-card>
                        </div>
                      </div>
                      <div class="col-md-12 mb-5">
                        <h6>Frage hochladen</h6>
                        <div role="tablist">
                          <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" role="tab">
                              <h6 class="m-0">
                                <a
                                  v-b-toggle.accordion-51
                                  class="text-dark"
                                  href="javascript: void(0);"
                                  >Spalte auswählen</a
                                >
                              </h6>
                            </b-card-header>
                            <b-collapse
                              id="accordion-51"
                              visible
                              accordion="my-accordion"
                              role="tabpanel"
                            >
                              <b-card-body>
                                <b-card-text>
                                  <ul>
                                    <li>
                                      Wählen Sie die Spalte der gewünschten
                                      Frage aus.
                                    </li>
                                  </ul>
                                </b-card-text>
                              </b-card-body>
                            </b-collapse>
                          </b-card>
                          <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" role="tab">
                              <h6 class="m-0">
                                <a
                                  v-b-toggle.accordion-62
                                  class="text-dark"
                                  href="javascript: void(0);"
                                  >Daten bestimmen</a
                                >
                              </h6>
                            </b-card-header>
                            <b-collapse
                              id="accordion-62"
                              visible
                              accordion="my-accordion"
                              role="tabpanel"
                            >
                              <b-card-body>
                                <b-card-text>
                                  <ul>
                                    <li>
                                      Bestimmen Sie die Spalte mit “Frage”.
                                    </li>
                                  </ul>
                                </b-card-text>
                              </b-card-body>
                            </b-collapse>
                          </b-card>
                          <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" role="tab">
                              <h6 class="m-0">
                                <a
                                  v-b-toggle.accordion-63
                                  class="text-dark"
                                  href="javascript: void(0);"
                                  >Spaltenname</a
                                >
                              </h6>
                            </b-card-header>
                            <b-collapse
                              id="accordion-63"
                              visible
                              accordion="my-accordion"
                              role="tabpanel"
                            >
                              <b-card-body>
                                <b-card-text>
                                  <ul>
                                    <li>
                                      Sie können die Bezeichnung Ihrer
                                      Fragespalte beliebig ändern.
                                    </li>
                                    <li>
                                      Wenn Sie die Bezeichnung nicht ändern,
                                      wird die Bezeichnung aus dem Datenfile
                                      verwendet.
                                    </li>
                                  </ul>
                                </b-card-text>
                              </b-card-body>
                            </b-collapse>
                          </b-card>
                          <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" role="tab">
                              <h6 class="m-0">
                                <a
                                  v-b-toggle.accordion-65
                                  class="text-dark"
                                  href="javascript: void(0);"
                                  >Projektiv-Fragetyp
                                </a>
                              </h6>
                            </b-card-header>
                            <b-collapse
                              id="accordion-65"
                              visible
                              accordion="my-accordion"
                              role="tabpanel"
                            >
                              <b-card-body>
                                <div class="row">
                                  <div class="col-md-12">
                                    <div class="card">
                                      <div class="card-body">
                                        <label> Vollständig </label>
                                        <p>
                                          Genaue Verteilung der
                                          unterschiedlichen Antwortoptionen
                                        </p>
                                        <p>
                                          Projektionstyp - Vollständig - Angaben
                                        </p>
                                        <ul>
                                          <li>Datenstruktur</li>
                                          <ul>
                                            <li>
                                              Die Antworten der Bezugsfrage
                                              dürfen sich nur in 1 Spalte
                                              befinden. Die einzelnen
                                              Projektionen müssen sich in einer
                                              eigenen Spalte befinden.
                                              <br />
                                              Beispiel: Frage mit 4
                                              Antwortoptionen = 5 Spalten
                                            </li>
                                          </ul>
                                          <li>
                                            Anzahl der projektiven
                                            Antwortoptionen
                                          </li>
                                          <ul>
                                            <li>
                                              Nach Angabe und Spezifizierung der
                                              Antwortoptionen, müssen Sie
                                              angeben wie viele Antwortoptionen
                                              projektiv erfasst wurden
                                              (projektive Spalten es für diese
                                              Frage im Datenfile).
                                            </li>
                                          </ul>
                                          <li>
                                            Spalte mit Projektionen auswählen
                                          </li>
                                          <ul>
                                            <li>
                                              Wählen Sie eine Spalte mit
                                              Projektionen, für eine
                                              Antwortoption aus dem
                                              Dropdown-Menü aus.
                                            </li>
                                          </ul>
                                          <li>
                                            Verknüpfte Antwortoption auswählen
                                          </li>
                                          <ul>
                                            <li>
                                              Geben Sie an, mit welcher
                                              Antwortoption die
                                              Projektionsspalte verknüpft werden
                                              soll und speichern Sie den Vorgang
                                            </li>
                                          </ul>
                                          <li>
                                            Führen Sie diesen Schritt für alle
                                            für alle Antwortoptionen mit
                                            Projektionsspalte durch und
                                            speichern Sie im Anschluss die
                                            Frage.
                                          </li>
                                          <li>
                                            Antwortoptionen die keine projektive
                                            Spalte zugewiesen bekommen (z.B.
                                            Keine Antwort) haben, werden im
                                            Ergebnis nicht gewichtet.
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-12">
                                    <div class="card">
                                      <div class="card-body">
                                        <label> Durchschnitt </label>
                                        <p>
                                          Durchschnittliche Antwort auf einer
                                          numerischen Skala
                                        </p>
                                        <p>
                                          Projektionstyp - Durchschnitt -
                                          Angaben
                                        </p>
                                        <ul>
                                          <li>Datenstruktur</li>
                                          <ul>
                                            <li>
                                              Die Antworten der Bezugsfrage
                                              dürfen sich nur in 1 Spalte
                                              befinden. Die Projektionen müssen
                                              sich in einer zweiten Spalte
                                              befinden.
                                              <br />
                                              Wichtig! Die Codierung der
                                              Antworten in der Fragespalte muss
                                              ident mit der Projektionsspalte
                                              sein.
                                            </li>
                                          </ul>
                                          <li>Beginn / Ende</li>
                                          <ul>
                                            <li>
                                              Da der Projektionstyp Durchschnitt
                                              nur für numerische Skalen
                                              verwendet werden kann, muss
                                              festgelegt werden bei welchen
                                              Werten diese beginnt und endet
                                            </li>
                                            <li>
                                              Alle Werte außerhalb dieses
                                              Bereichs werden zwar erfasst,
                                              jedoch nicht für die Gewichtung
                                              berücksichtigt.
                                            </li>
                                          </ul>
                                          <li>
                                            Spalte mit Projektionen auswählen
                                          </li>
                                          <ul>
                                            <li>
                                              Wählen Sie die Spalte mit den
                                              Projektionen aus dem Dropdown-Menü
                                              aus und speichern Sie den Vorgang
                                              als auch die Frage.
                                            </li>
                                          </ul>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-12">
                                    <div class="card">
                                      <div class="card-body">
                                        <label> Übereinstimmung </label>
                                        <p>
                                          Prozentuelle Übereinstimmung mit
                                          eigener Antwort
                                        </p>
                                        <p>
                                          Projektionstyp - Übereinstimmung -
                                          Angaben
                                        </p>
                                        <ul>
                                          <li>Datenstruktur</li>
                                          <ul>
                                            <li>
                                              Die Antworten der Bezugsfrage
                                              dürfen sich nur in 1 Spalte
                                              befinden. Die Projektionen müssen
                                              sich in einer zweiten Spalte
                                              befinden.
                                            </li>
                                          </ul>
                                          <li>
                                            Bei diesem Projektionstyp werden
                                            standardmäßig alle Antwortoptionen
                                            gewichtet.
                                          </li>
                                          <li>
                                            Spalte mit Projektionen auswählen
                                          </li>
                                          <ul>
                                            <li>
                                              Wählen Sie die Spalte mit den
                                              Projektionen aus dem Dropdown-Menü
                                              aus und speichern Sie den Vorgang
                                              als auch die Frage.
                                            </li>
                                          </ul>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-12">
                                    <div class="card">
                                      <div class="card-body">
                                        <label> Nicht-Projektive Fragen </label>
                                        <p>
                                          Sie können auch Fragen, die keine
                                          zugehörige projektive Frage haben
                                          hochladen.
                                        </p>
                                        <p>
                                          Diese Fragen haben keinen Einfluss auf
                                          die Berechnung der Redem-Scores -
                                          können aber durch Anwendung der Redem
                                          Gewichtung analysiert werden.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </b-card-body>
                            </b-collapse>
                          </b-card>
                          <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" role="tab">
                              <h6 class="m-0">
                                <a
                                  v-b-toggle.accordion-54
                                  class="text-dark"
                                  href="javascript: void(0);"
                                  >Themenkategorie
                                </a>
                              </h6>
                            </b-card-header>
                            <b-collapse
                              id="accordion-54"
                              visible
                              accordion="my-accordion"
                              role="tabpanel"
                            >
                              <b-card-body>
                                <b-card-text>
                                  <ul>
                                    <li>
                                      Wählen Sie aus der folgenden Kategorien
                                      aus, die für Ihre Frage zutrifft
                                    </li>
                                    <ul>
                                      <li>Kaufabsichten</li>
                                      <li>Konsumforschung</li>
                                      <li>Markenwarnehmung</li>
                                      <li>Politikforschung</li>
                                      <li>Sozialforschung</li>
                                      <li>Kundenzufriedenheit</li>
                                      <ul>
                                        <li>
                                          Bestimmen Sie ob es sich bei Ihrer
                                          Frage um eine Frage nach “NPS - Net
                                          Promoter Score” Format handelt
                                        </li>
                                      </ul>
                                      <li>Mitarbeiterzufriedenheit</li>
                                      <li>Sonstige</li>
                                      <ul>
                                        <li>
                                          Sie können im Textfeld angeben um
                                          welche Kategorie es sich handelt.
                                        </li>
                                      </ul>
                                    </ul>
                                    <li>Diese Angabe ist optional</li>
                                  </ul>
                                </b-card-text>
                              </b-card-body>
                            </b-collapse>
                          </b-card>
                          <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" role="tab">
                              <h6 class="m-0">
                                <a
                                  v-b-toggle.accordion-67
                                  class="text-dark"
                                  href="javascript: void(0);"
                                  >Inhaltlicher Fokus
                                </a>
                              </h6>
                            </b-card-header>
                            <b-collapse
                              id="accordion-67"
                              visible
                              accordion="my-accordion"
                              role="tabpanel"
                            >
                              <b-card-body>
                                <b-card-text>
                                  <ul>
                                    <li>
                                      Geben Sie an welche Inhaltliche
                                      Ausrichtung Ihre Frage aufweist. Wählen
                                      Sie unter einer der folgenden
                                      Möglichkeiten aus.
                                    </li>
                                    <ul>
                                      <li>Meinung</li>
                                      <li>Verhalten</li>
                                      <li>Wissen</li>
                                    </ul>
                                    <li>
                                      Die Angabe des inhaltlichen Fokus ist nur
                                      für projektive Fragen notwendig.
                                    </li>
                                    <li>Diese Angabe ist optional</li>
                                  </ul>
                                </b-card-text>
                              </b-card-body>
                            </b-collapse>
                          </b-card>
                          <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" role="tab">
                              <h6 class="m-0">
                                <a
                                  v-b-toggle.accordion-68
                                  class="text-dark"
                                  href="javascript: void(0);"
                                  >Frage zeitlich bezogen auf
                                </a>
                              </h6>
                            </b-card-header>
                            <b-collapse
                              id="accordion-68"
                              visible
                              accordion="my-accordion"
                              role="tabpanel"
                            >
                              <b-card-body>
                                <b-card-text>
                                  <ul>
                                    <li>
                                      Auf welche zeitliche Ebene bezieht sich
                                      Ihre Frage?
                                    </li>
                                    <ul>
                                      <li>Vergangenheit</li>
                                      <li>Gegenwart</li>
                                      <li>Zukunft</li>
                                    </ul>
                                    <li>Diese Angabe ist optional</li>
                                  </ul>
                                </b-card-text>
                              </b-card-body>
                            </b-collapse>
                          </b-card>
                          <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" role="tab">
                              <h6 class="m-0">
                                <a
                                  v-b-toggle.accordion-69
                                  class="text-dark"
                                  href="javascript: void(0);"
                                  >Inhaltliche Effekte (Mehrfachauswahl möglich)
                                </a>
                              </h6>
                            </b-card-header>
                            <b-collapse
                              id="accordion-69"
                              visible
                              accordion="my-accordion"
                              role="tabpanel"
                            >
                              <b-card-body>
                                <b-card-text>
                                  <ul>
                                    <li>
                                      Geben Sie bitte an, ob einer oder mehrere
                                      dieser inhaltlichen Effekte auf Ihre Frage
                                      zutreffen könnten
                                    </li>
                                    <ul>
                                      <li>
                                        Heikle Frage (Soziale Erwünschtheit)
                                      </li>
                                      <li>
                                        Frage ist eine Aussage mit
                                        Übereinstimmungsskala
                                      </li>
                                      <li>
                                        Informationsvorsprung einzelner
                                        Befragter
                                      </li>
                                    </ul>
                                    <li>Diese Angabe ist optional</li>
                                  </ul>
                                </b-card-text>
                              </b-card-body>
                            </b-collapse>
                          </b-card>
                          <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" role="tab">
                              <h6 class="m-0">
                                <a
                                  v-b-toggle.accordion-66
                                  class="text-dark"
                                  href="javascript: void(0);"
                                  >Antwortoptionen
                                </a>
                              </h6>
                            </b-card-header>
                            <b-collapse
                              id="accordion-66"
                              visible
                              accordion="my-accordion"
                              role="tabpanel"
                            >
                              <b-card-body>
                                <b-card-text>
                                  <ul>
                                    <li>
                                      Hier finden Sie alle Antwortoptionen, die
                                      aus der Fragespalte ausgelesen wurden.
                                    </li>
                                    <li>
                                      Sie können die Bezeichnung beliebig
                                      ändern. Standardmäßig wird die ausgelesene
                                      Bezeichnung verwendet.
                                    </li>
                                    <li>
                                      Weiters kann die Reihenfolge der
                                      Antwortoption geändert werden.
                                    </li>
                                    <li>
                                      Es gibt auch die Möglichkeit, eine
                                      Antwortoption von der Gewichtung
                                      auszuschließen. Das kann bei
                                      Antwortoptionen wie zum Beispiel, “Weiß
                                      nicht” oder “Keine Antwort” sinnvoll sein.
                                    </li>
                                    <li>
                                      Bei Bedarf können Sie auch Antwortoptionen
                                      hinzufügen, die keine Stimmen erhalten
                                      haben und dadurch nicht ausgelesen werden
                                      konnten. Dadurch lassen sich alle
                                      Antwortoptionen aus Ihrem Fragebogen
                                      abbilden.
                                    </li>
                                  </ul>
                                </b-card-text>
                              </b-card-body>
                            </b-collapse>
                          </b-card>
                          <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" role="tab">
                              <h6 class="m-0">
                                <a
                                  v-b-toggle.accordion-73
                                  href="javascript: void(0);"
                                  class="text-dark"
                                  style="cursor: default"
                                  >Speichern Sie den Vorgang</a
                                >
                              </h6>
                            </b-card-header>
                          </b-card>
                        </div>
                      </div>
                      <div class="col-md-12 mb-5">
                        <h6 class="mb-3">Filtervariable hochladen</h6>
                        <div role="tablist">
                          <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" role="tab">
                              <h6 class="m-0">
                                <a
                                  v-b-toggle.accordion-61
                                  class="text-dark"
                                  href="javascript: void(0);"
                                  >Spalte auswählen</a
                                >
                              </h6>
                            </b-card-header>
                            <b-collapse
                              id="accordion-61"
                              visible
                              accordion="my-accordion"
                              role="tabpanel"
                            >
                              <b-card-body>
                                <b-card-text>
                                  <ul>
                                    <li>
                                      Wählen Sie die Spalte Ihrer Filtervariable
                                      aus dem Datenfile aus.
                                    </li>
                                  </ul>
                                </b-card-text>
                              </b-card-body>
                            </b-collapse>
                          </b-card>
                          <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" role="tab">
                              <h6 class="m-0">
                                <a
                                  v-b-toggle.accordion-62
                                  class="text-dark"
                                  href="javascript: void(0);"
                                  >Daten bestimmen</a
                                >
                              </h6>
                            </b-card-header>
                            <b-collapse
                              id="accordion-62"
                              visible
                              accordion="my-accordion"
                              role="tabpanel"
                            >
                              <b-card-body>
                                <b-card-text>
                                  <ul>
                                    <li>
                                      Bestimmen Sie die Spalte mit
                                      “Filtervariable”.
                                    </li>
                                  </ul>
                                </b-card-text>
                              </b-card-body>
                            </b-collapse>
                          </b-card>
                          <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" role="tab">
                              <h6 class="m-0">
                                <a
                                  v-b-toggle.accordion-63
                                  class="text-dark"
                                  href="javascript: void(0);"
                                  >Spaltenname</a
                                >
                              </h6>
                            </b-card-header>
                            <b-collapse
                              id="accordion-63"
                              visible
                              accordion="my-accordion"
                              role="tabpanel"
                            >
                              <b-card-body>
                                <b-card-text>
                                  <ul>
                                    <li>
                                      Sie können die Bezeichnung Ihrer
                                      Filtervariable beliebig ändern.
                                    </li>
                                    <li>
                                      Wenn Sie die Bezeichnung nicht ändern,
                                      wird die Bezeichnung aus dem Datenfile
                                      verwendet.
                                    </li>
                                  </ul>
                                </b-card-text>
                              </b-card-body>
                            </b-collapse>
                          </b-card>
                          <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" role="tab">
                              <h6 class="m-0">
                                <a
                                  v-b-toggle.accordion-64
                                  class="text-dark"
                                  href="javascript: void(0);"
                                  >Filteroptionen
                                </a>
                              </h6>
                            </b-card-header>
                            <b-collapse
                              id="accordion-64"
                              visible
                              accordion="my-accordion"
                              role="tabpanel"
                            >
                              <b-card-body>
                                <b-card-text>
                                  <ul>
                                    <li>
                                      Alle Filteroptionen werden aus der
                                      ausgewählten Spalte ausgelesen.
                                    </li>
                                    <li>
                                      Sie können die Bezeichnungen der
                                      Filteroptionen ändern. Auch hier wird
                                      standardmäßig die ausgelesene Bezeichnung
                                      verwendet.
                                    </li>
                                    <li>
                                      Bestätigen Sie Ihre Eingaben, indem Sie
                                      auf die Schaltfläche “Filtervariable
                                      speichern” klicken.
                                    </li>
                                  </ul>
                                </b-card-text>
                              </b-card-body>
                            </b-collapse>
                          </b-card>
                          <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" role="tab">
                              <h6 class="m-0">
                                <a
                                  v-b-toggle.accordion-73
                                  href="javascript: void(0);"
                                  class="text-dark"
                                  style="cursor: default"
                                  >Speichern Sie den Vorgang</a
                                >
                              </h6>
                            </b-card-header>
                          </b-card>
                        </div>
                      </div>
                      <div class="col-md-12 mb-5">
                        <h6 class="mb-3">
                          Zusätzlichen Gewichtungsfaktor hochladen
                        </h6>
                        <div role="tablist">
                          <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" role="tab">
                              <h6 class="m-0">
                                <a
                                  v-b-toggle.accordion-71
                                  class="text-dark"
                                  href="javascript: void(0);"
                                  >Spalte auswählen</a
                                >
                              </h6>
                            </b-card-header>
                            <b-collapse
                              id="accordion-71"
                              visible
                              accordion="my-accordion"
                              role="tabpanel"
                            >
                              <b-card-body>
                                <b-card-text>
                                  <ul>
                                    <li>
                                      Wählen Sie die Spalte, in der sich der
                                      zusätzliche Gewichtungsfaktor befindet,
                                      aus.
                                    </li>
                                  </ul>
                                </b-card-text>
                              </b-card-body>
                            </b-collapse>
                          </b-card>
                          <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" role="tab">
                              <h6 class="m-0">
                                <a
                                  v-b-toggle.accordion-72
                                  class="text-dark"
                                  href="javascript: void(0);"
                                  >Daten bestimmen</a
                                >
                              </h6>
                            </b-card-header>
                            <b-collapse
                              id="accordion-72"
                              visible
                              accordion="my-accordion"
                              role="tabpanel"
                            >
                              <b-card-body>
                                <b-card-text>
                                  <ul>
                                    <li>
                                      Bestimmen Sie die Spalte mit “Zusätzlicher
                                      Gewichtungsfaktor.
                                    </li>
                                  </ul>
                                </b-card-text>
                              </b-card-body>
                            </b-collapse>
                          </b-card>
                          <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" role="tab">
                              <h6 class="m-0">
                                <a
                                  v-b-toggle.accordion-73
                                  href="javascript: void(0);"
                                  class="text-dark"
                                  style="cursor: default"
                                  >Speichern Sie den Vorgang</a
                                >
                              </h6>
                            </b-card-header>
                          </b-card>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
              <!-- Step 3-->
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="mdi mdi-numeric-3-circle"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Schritt 3</span>
                </template>
                <div class="row mt-3">
                  <div class="col-xl-12">
                    <ul>
                      <li>
                        Im letzten Schritt erhalten Sie eine Zusammenfassung
                        über alle Angaben.
                      </li>
                      <li class="mt-3">
                        Kontrollieren Sie diese Angaben bitte genau.
                        <ul>
                          <li>
                            Sie können jederzeit wieder zu Schritt 2 und 1
                            zurückkehren um Ihre Angaben zu ändern.
                          </li>
                        </ul>
                      </li>
                      <li class="mt-3">
                        Weiters finden Sie die Berechnung über die benötigten
                        Credits, die für das Hochladen Ihres Datenfiles
                        notwendig sind.
                      </li>
                      <li class="mt-3">
                        Bestätigen Sie Ihre Eingaben, indem Sie die auf
                        Schaltfläche “Bestätigen und Hochladen” klicken.
                      </li>
                      <li class="mt-3">
                        Sollte beim Hochladen des Datenfiles ein Fehler
                        auftreten - kontrollieren Sie bitte Ihre Angaben. Sollte
                        der Fehler erneut auftreten - kontaktieren Sie bitte
                        unseren Support unter folgender Email: support@redem.io
                      </li>
                    </ul>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
